<template>
  <section class="about">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      max-height="420"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
        </v-layout>
      </v-img>
    <v-container  grid-list-md text-center class="ctk-div-m">
      <div>
        <v-breadcrumbs :items="breadcrumbs" divider=" / "></v-breadcrumbs>
      </div>
      <v-layout wrap>
        <v-flex class="text-left">
          <span v-html="about.introduction">{{about.introduction}}</span>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 10,
    innerWidth: window.innerWidth,
    about: {
      'introduction': null
    },
    breadcrumbs: []
  }),
  created () {
    document.querySelector('#menu_about').classList.add('v-btn--active')
    this.getAbout()
    this.breadcrumbs = [
      { text: '首页', disabled: false, href: '/' },
      { text: '关于我们', disabled: true },
      { text: '关于柯尼', disabled: true }
    ]
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      let links = []
      if (this.$store.getters.companyInfo.links) {
        links = this.$store.getters.companyInfo.links
      }
      return {
        links: links,
        banners: banners
      }
    }
  },
  methods: {
    getAbout () {
      this.https.get('about').then(response => {
        if (response.code === 0) {
          this.about = response.data
        }
      })
    }
  }
}
</script>
